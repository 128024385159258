import { object, string, number } from 'yup';

export const validationSchema = object({
  type: string().required('Property type is required'),
  property_name: string()
    .matches(/^[\u4e00-\u9fa5a-zA-Z0-9', /()-@#$%^&*]+$/, 'Invalid Property Name')
    .required('Property name is required'),
  block: string()
    .trim()
    .matches(/^[a-zA-Z0-9, /()-]+$/, 'Invalid Block Name')
    .optional(),
  street_name: string()
    .trim()
    .matches(/^[a-zA-Z0-9', /()-[\]{}!@#$%^&*]+$/, 'Invalid Street Name')
    .required('Street name is required'),
  postal_code: string()
    .trim()
    .matches(/^[0-9]+$/, 'Postal code must be numbers')
    .optional(),
  district_id: string().test('conditional-validity-required', function (value) {
    const { type } = this.parent;
    const isValidityRequired = type !== '1';
    if (isValidityRequired && !value) {
      return this.createError({ message: 'District is required' });
    }
    return true;
  }),
  locality: string()
    .trim()
    .matches(/^[a-zA-Z0-9', /()-[\]{}!@#$%^&*]+$/, 'Invalid Locality')
    .optional(),
  year_built: string()
    .matches(/^[a-zA-Z0-9, /()-]+$/, 'Special characters not allowed')
    .optional(),
  tenure: string().trim().max(30, 'Tenure should be maximum 30 characters').optional(),
  highest_storey: string()
    .matches(/^[a-zA-Z0-9, /()-]+$/, 'Special characters not allowed')
    .optional(),
  total_units: string()
    .matches(/^[a-zA-Z0-9, /()-]+$/, 'Special characters not allowed')
    .optional(),
  total_towers: string()
    .matches(/^[a-zA-Z0-9, /()-]+$/, 'Special characters not allowed')
    .optional(),
  tower_name: string()
    // .matches(/^[a-zA-Z0-9', /()-@#$%^&*]+$/, 'Invalid Tower Name')
    .optional(),
  developer: string()
    .trim()
    .matches(/^[a-zA-Z0-9', /()-[\]{}!@#$%^&*]+$/, 'Invalid Developer')
    .optional(),
  is_new_launch: string().trim().optional(),
  is_ad_space: string().trim().optional(),
  plan_id_1: string()
    .nullable()
    .test('conditional-validity-required', function (value) {
      const { is_ad_space } = this.parent;
      const isValidityRequired = is_ad_space === 'true';
      if (isValidityRequired && !value) {
        return this.createError({ message: 'Plan for Ad space is required' });
      }
      return true;
    }),
  total_slot_1: number()
    .nullable()
    .typeError('Total Slot must be a number')
    .test('max-value', 'Total Slot must be less than or equal to 18', (value) => value <= 18)
    .test('conditional-validity-required', 'Total Slot for Ad space is required', function (value) {
      const { is_ad_space } = this.parent;
      const isValidityRequired = is_ad_space === true;
      if (isValidityRequired && !value) {
        return this.createError({ message: 'Total Slot for Ad space is required' });
      }
      return true;
    }),
  is_tagger: string().trim().optional(),
  plan_id_2: string()
    .nullable()
    .test('conditional-validity-required', function (value) {
      const { is_tagger } = this.parent;
      const isValidityRequired = is_tagger === 'true';
      if (isValidityRequired && !value) {
        return this.createError({ message: 'Plan for Tagger is required' });
      }
      return true;
    }),
  total_slot_2: number()
    .nullable()
    .typeError('Total Slot must be a number')
    .test('max-value', 'Total Slot must be less than or equal to 18', (value) => value <= 18)
    .test('conditional-validity-required', 'Total Slot Tagger is required', function (value) {
      const { is_tagger } = this.parent;
      const isValidityRequired = is_tagger === true;
      if (isValidityRequired && !value) {
        return this.createError({ message: 'Total Slot Tagger is required' });
      }
      return true;
    })
    .test('numbers-only', 'Total Slot must be a number', (value) => Number.isInteger(value)),
  nearest_mrt: string()
    .trim()
    .matches(/^[a-zA-Z0-9', /()-[\]{}!@#$%^&*]+$/, 'Invalid MRT name')
    .nullable()
    .optional(),
  mrt_distance: number().typeError('MRT distance must be a number').nullable().optional(),
  longitude: string()
    .trim()
    .matches(/^-?(180(\.0+)?|((1[0-7]\d)|(\d{1,2}))(\.\d+)?)$/, 'Invalid Longitude')
    .nullable()
    .optional(),
  latitude: string()
    .trim()
    .matches(/^-?([0-8]?\d(\.\d+)?|90(\.0+)?)$/, 'Invalid Latitude')
    .nullable()
    .optional()
});

export const initialValues = {
  type: '',
  property_name: '',
  block: '',
  street_name: '',
  postal_code: '',
  district_id: '',
  locality: '',
  year_built: '',
  tenure: '',
  highest_storey: '',
  total_units: '',
  total_towers: '',
  tower_name: '',
  developer: '',
  is_new_launch: false,
  is_ad_space: false,
  plan_id_1: '',
  total_slot_1: 6,
  is_tagger: false,
  plan_id_2: '',
  total_slot_2: 6,
  nearest_mrt: '',
  mrt_distance: '',
  longitude: '',
  latitude: ''
};
export default validationSchema;
